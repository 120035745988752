import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Grid, Header } from 'semantic-ui-react'
import './HomeShortContact.css';
import ReactGA from 'react-ga';

const BREAKPOINTS = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  largeScreen: 1200,
  widescreen: 1920
}

function HomeShortContact() {
  const history = useHistory();

  const handleButton = () => {
    history.push("/contact");

    ReactGA.event({
      category: 'Home',
      action: 'Clicked a Navigation Button',
      label: 'contact'
    });
  }

  return (
    <div className="home-short-contact-root">
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column textAlign='center'>
            <Header as='h3' className={window.innerWidth > BREAKPOINTS.tablet ? "home-short-contact-header" : "home-short-contact-header-mobile"}>
              Get In Touch.
            </Header>
            <br />
            <p>I’m always looking for new opportunities to help students achieve their fullest potential
              and further the work that education as a whole brings to our local communities, nation, and even the entire world!
            </p>
            <br />
            <p><i>“The more that you read, the more things you will know, the more that you learn, the
              more places you’ll go.” <b>– Dr. Seuss</b></i>
            </p>
            <br />
            <div>
              <Button
                secondary
                onClick={handleButton}
              >
                Contact Me
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default HomeShortContact;