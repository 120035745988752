import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header, Image } from 'semantic-ui-react';
import './HomeShortBio.css';
import jordan from '../images/jordan.JPG';
import ReactGA from 'react-ga';

const BREAKPOINTS = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  largeScreen: 1200,
  widescreen: 1920
}

function HomeShortBio() {
  const history = useHistory();

  const handleButton = () => {
    history.push("/about");

    ReactGA.event({
      category: 'Home',
      action: 'Clicked a Navigation Button',
      label: 'about'
    });
  }

  return (
    <div className="home-short-bio-root">
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column floated='right' width={8}>
            <Image size='large' src={jordan} alt='Jordan Polera' />
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as='h3' className={window.innerWidth > BREAKPOINTS.tablet ? "home-short-bio-header" : "home-short-bio-header-mobile"}>
              A Little About Myself.
            </Header>
            <p>
              Teaching is my passion! With a Bachelor's Degree, double majoring in Elementary and Special Education,
              and a Licensure for Academically and Intellectually Gifted Education, I'm prepared and dedicated in teaching
              my students everything they need to know in order to be successful in their pursuit of learning.
            </p>
            <br />
            <p>I'm married to an amazing husband, have the cutest dog in world, and am a passionate teacher.</p>
            <div>
              <Button
                secondary
                onClick={handleButton}
              >
                Learn More
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default HomeShortBio;