import React, { useEffect } from 'react';
import './Contact.css';
import { Header, Grid } from 'semantic-ui-react';
import Footer from '../components/Footer';

const BREAKPOINTS = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  largeScreen: 1200,
  widescreen: 1920
}

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-root">
      <div className={window.innerWidth > BREAKPOINTS.tablet ? "contact-main" : "contact-main-mobile"} />

      <Grid container stackable centered className="contact-grid">
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' className={window.innerWidth > BREAKPOINTS.tablet ? "contact-header" : "contact-header-mobile"}>
              Get in Touch.
            </Header>
            <p>
              Interested in reaching out? Please fill out the form and I'd be happy to hear from you!
            </p>
          </Grid.Column>
          <Grid.Column width={8} textAlign={window.innerWidth > BREAKPOINTS.tablet ? 'right' : 'center'}>
            <iframe
              title="Contact information"
              src="https://docs.google.com/forms/d/e/1FAIpQLSdqC_K51LQpdxqfTIyAIJOMlTQJXW8F2eS3ONxgTt4tbjOe6g/viewform?embedded=true"
              width={window.innerWidth > BREAKPOINTS.tablet ? "640" : (window.innerWidth - 30)}
              height="760"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0">
              Loading...
            </iframe>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Footer />
    </div>
  );
}

export default Contact;
