import React from 'react'
import { Container } from 'semantic-ui-react'
import './HomeMain.css'

const BREAKPOINTS = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  largeScreen: 1200,
  widescreen: 1920
}

function HomeMain() {
  return (
    <div className={window.innerWidth > BREAKPOINTS.tablet ? "home-main-root" : "home-main-root-mobile"}>
      <Container className={window.innerWidth > BREAKPOINTS.tablet ? "home-main-container" : "home-main-container-mobile"}>
        <p className={window.innerWidth > BREAKPOINTS.tablet ? "home-main-desc" : "home-main-desc-mobile"}>Hi, I'm <strong>Jordan <br />Polera</strong>. A teacher <br /> inspiring the <br />future generation.</p>
      </Container>
    </div>
  )
}

export default HomeMain;