import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Grid, Header } from 'semantic-ui-react'
import Carousel from 'nuka-carousel';
import './HomeExperience.css';
import ReactGA from 'react-ga';

import governorsSTEM from '../images/governors_stem_academy_logo.jpg';
import moreheadSTEM from '../images/morehead_stem_academy_logo.jpg';
import vegaElementary from '../images/vega_elementary.jpg';

const BREAKPOINTS = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  largeScreen: 1200,
  widescreen: 1920
}

const description = "For the past several years, I've had the opportunity to teach at STEM academies teaching all available subjects. "
  + "Recently, I had the opportunity to take a position as the school’s Talent Development Teacher for gifted students in grades K-5. "
  + "My greatest strengths lie in Math and Technology; however, I work diligently to ensure my students are well instructed in all other areas."

function HomeExperience() {
  const history = useHistory();

  const handleButton = () => {
    history.push("/experience");

    ReactGA.event({
      category: 'Home',
      action: 'Clicked a Navigation Button',
      label: 'experience'
    });
  }

  return (
    <div className="home-experience-root">
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column textAlign='center'>
            <Header as='h3' className={window.innerWidth > BREAKPOINTS.tablet ? "home-experience-header" : "home-experience-header-mobile"}>
              My Experience.
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={14}>
            <p>{description}</p>
          </Grid.Column>
          <Grid.Column width={2} textAlign='center'>
            <div>
              <Button
                secondary
                onClick={handleButton}
              >
                Explore
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
          <Carousel
            className='home-experience-carousel'
            cellAlign="center"
            initialSlideHeight={453}
            autoplay
            autoplayInterval={5000}
            wrapAround
            renderCenterLeftControls={null}
            renderCenterRightControls={null}
          >
            <img src={vegaElementary} alt="Vega Elementary" />
            <img src={governorsSTEM} alt="Governors' Village STEM Academy" />
            <img src={moreheadSTEM} alt="John Motley Morehead STEM Academy" />
          </Carousel>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default HomeExperience;