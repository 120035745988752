import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './Experience.css';
import { Button, Divider, Image, Grid, Header } from 'semantic-ui-react';
import Footer from '../components/Footer';
import ReactGA from 'react-ga';

import governorsSTEM from '../images/governors_stem_academy_logo.jpg';
import moreheadSTEM from '../images/morehead_stem_academy_logo.jpg';
import vegaElementary from '../images/vega_elementary.jpg';

const BREAKPOINTS = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  largeScreen: 1200,
  widescreen: 1920
}

function Experience() {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleButton = () => {
    history.push("/contact");

    ReactGA.event({
      category: 'Experience',
      action: 'Clicked a Navigation Button',
      label: 'contact'
    });
  }

  return (
    <div className="experience-root">
      <div className={window.innerWidth > BREAKPOINTS.tablet ? "experience-main" : "experience-main-mobile"} />

      <Grid container stackable verticalAlign='middle' centered className="experience-grid">
        <Grid.Row>
          <Grid.Column width={15}>
            <Header as='h3' className={window.innerWidth > BREAKPOINTS.tablet ? "experience-header" : "experience-header-mobile"}>
              My Experience.
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12}>
            <p>
              Since graduating from UNC Charlotte, I've had the great opportunity to teach at several schools. All of them have allowed me to grow
              and develop my teaching capabilities and have strengthened my love and passion for teaching.
            </p>
          </Grid.Column>
          <Grid.Column width={3} textAlign={window.innerWidth > BREAKPOINTS.tablet ? 'right' : 'center'}>
            <div>
              <Button secondary inverted onClick={handleButton}>
                Contact Me
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={15}>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={15}>
            <Image src={vegaElementary} alt="Vega Elementary" fluid />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={15}>
            <Image src={governorsSTEM} alt="Governors' Village STEM Academy" fluid />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={15}>
            <Image src={moreheadSTEM} alt="John Motley Morehead STEM Academy" fluid />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Footer />
    </div>
  );
}

export default Experience;
