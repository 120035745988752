import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import Navigation from './navigation/Navigation';
import Home from './home/Home';
import Experience from './experience/Experience';
import About from './about/About';
import Contact from './contact/Contact';

const appStyle = {
  height: '100%'
}

const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

function App() {
  return (
    <Router>
      <div className="App" style={appStyle}>
        <Navigation />
        <Route exact path='/'>
          <Redirect to="/home" />
        </Route>
        <Route path='/home'>
          <Home />
        </Route>
        <Route path='/experience'>
          <Experience />
        </Route>
        <Route path='/about'>
          <About />
        </Route>
        <Route path='/contact'>
          <Contact />
        </Route>
      </div >
    </Router >
  );
}

export default App;
