import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Icon, Modal } from 'semantic-ui-react';
import ReactGA from 'react-ga';

const BREAKPOINTS = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  largeScreen: 1200,
  widescreen: 1920
}

class Navigation extends Component {
  state = { activeItem: 'home', isTop: true, modalOpen: false }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name, modalOpen: false });
    this.props.history.push(name);

    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked a Button',
      label: name
    });
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    let pathName = this.props.location.pathname.substring(1);

    if (pathName !== null && pathName !== '')
      this.setState({ activeItem: pathName });

    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({ activeItem: location.pathname.substring(1) });
    });

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.unlisten();
  }

  handleScroll = () => {
    if (window.scrollY !== 0 && this.isTop !== false) {
      this.setState({ isTop: false });
    } else if (this.isTop !== true) {
      this.setState({ isTop: true });
    }
  };

  render() {
    const { activeItem, isTop } = this.state;

    const menuStyle = {
      padding: isTop ? '20px 40px' : '5px 40px',
      background: isTop ? 'rgba(0, 0, 0, 0)' : '#ffffff',
      WebkitTransition: 'padding 0.25s', /* Safari */
      transition: 'padding 0.25s',
    }

    const menuMobileStyle = {
      padding: isTop ? '10px 10px' : '5px 10px',
      background: isTop ? 'rgba(0, 0, 0, 0)' : '#ffffff',
      WebkitTransition: 'padding 0.25s', /* Safari */
      transition: 'padding 0.25s',
    }

    const modalStyle = {
      height: '100%',
      width: '100%',
      borderRadius: 0
    }

    const modalMenuStyle = {
      textAlign: 'center'
    }

    const modalCancelDivStyle = {
      width: '100%',
      padding: isTop ? '10px 10px' : '5px 10px',
      textAlign: 'right'
    }

    return (
      <Menu
        borderless
        fixed='top'
        size={window.innerWidth > BREAKPOINTS.tablet ? 'massive' : 'large'}
        style={window.innerWidth > BREAKPOINTS.tablet ? menuStyle : menuMobileStyle}
        inverted={isTop}
      >
        <Menu.Item
          name='jordanpolera'
          onClick={() => {
            this.props.history.push("/home");

            ReactGA.event({
              category: 'Navigation',
              action: 'Clicked a Button',
              label: 'jordanpolera'
            });
          }}
        >
          JORDAN POLERA
        </Menu.Item>
        <Menu.Menu position='right'>
          {window.innerWidth > BREAKPOINTS.tablet ?
            <React.Fragment>
              <Menu.Item
                name='home'
                active={activeItem === 'home'}
                onClick={this.handleItemClick}
              >
                <h4>HOME</h4>
              </Menu.Item>
              <Menu.Item
                name='experience'
                active={activeItem === 'experience'}
                onClick={this.handleItemClick}
              >
                <h4>EXPERIENCE</h4>
              </Menu.Item>
              <Menu.Item
                name='about'
                active={activeItem === 'about'}
                onClick={this.handleItemClick}
              >
                <h4>ABOUT</h4>
              </Menu.Item>
              <Menu.Item
                name='contact'
                active={activeItem === 'contact'}
                onClick={this.handleItemClick}
              >
                <h4>CONTACT</h4>
              </Menu.Item>
            </React.Fragment>
            :
            <Modal
              style={modalStyle}
              open={this.state.modalOpen}
              onClose={this.handleClose}
              trigger={
                <Menu.Item icon onClick={this.handleOpen}>
                  <Icon name='bars' />
                </Menu.Item>
              }
            >
              <Modal.Content>
                <div style={modalCancelDivStyle}>
                  <Icon name='cancel' onClick={this.handleClose} />
                </div>
                <Menu vertical secondary fluid style={modalMenuStyle}>
                  <Menu.Item
                    name='home'
                    active={activeItem === 'home'}
                    onClick={this.handleItemClick}>
                    Home
                  </Menu.Item>
                  <Menu.Item
                    name='experience'
                    active={activeItem === 'experience'}
                    onClick={this.handleItemClick}>
                    Experience
                  </Menu.Item>
                  <Menu.Item
                    name='about'
                    active={activeItem === 'about'}
                    onClick={this.handleItemClick}>
                    About
                  </Menu.Item>
                  <Menu.Item
                    name='contact'
                    active={activeItem === 'contact'}
                    onClick={this.handleItemClick}>
                    Contact
                  </Menu.Item>
                </Menu>
              </Modal.Content>
            </Modal>
          }
        </Menu.Menu>
      </Menu>
    );
  }
}

export default withRouter(Navigation);
