import React, { useEffect } from 'react';

import HomeMain from './HomeMain';
import HomeShortBio from './HomeShortBio';
import HomeExperience from './HomeExperience';
import HomeShortContact from './HomeShortContact';
import Footer from '../components/Footer';

const homeStyle = {
  minHeight: '100%'
}

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Home" style={homeStyle}>
      <HomeMain />
      <HomeShortBio />
      <HomeExperience />
      <HomeShortContact />
      <Footer />
    </div>
  );
}

export default Home;
