import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './About.css';
import { Container, Button, List, Grid, Divider, Header, Image } from 'semantic-ui-react';
import Footer from '../components/Footer';
import ReactGA from 'react-ga';

import jordan from '../images/jordan_2.JPG';

const BREAKPOINTS = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  largeScreen: 1200,
  widescreen: 1920
}

const dividerStyle = {
  marginTop: '40px',
  marginBottom: '40px'
}

const contentInfoStyle = {
  padding: '40px 20px 80px 20px'
}

function About() {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleButton = () => {
    history.push("/experience");

    ReactGA.event({
      category: 'About',
      action: 'Clicked a Navigation Button',
      label: 'experience'
    });
  }

  return (
    <div className="about-root">
      <div className={window.innerWidth > BREAKPOINTS.tablet ? "about-main" : "about-main-mobile"} />

      <Grid container stackable verticalAlign='middle' centered className="about-grid">
        <Grid.Row>
          <Grid.Column width={15}>
            <Header as='h3' className={window.innerWidth > BREAKPOINTS.tablet ? "about-header" : "about-header-mobile"}>
              About Me.
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <Image size='large' src={jordan} alt='Jordan Polera' />
          </Grid.Column>
          <Grid.Column width={8} textAlign={window.innerWidth > BREAKPOINTS.tablet ? 'left' : 'center'}>
            <p>
              I started my educational journey in January of 2011 to attend the University of North Carolina at Charlotte and I have been here ever since!
              My love for teaching drove me to complete a double major in Elementary and Special Education in May of 2014. Recently, I completed a
              Graduate program from the University of North Carolina in Wilmington to receive my Academically and Intellectually Gifted Teaching
              Certification.
            </p>
            <br />
            <p>
              I have been teaching since the fall of 2014 and love what I do. I've learned so much from all of my students and the greatest joy I recieve is
              when I see them progress from the beginning of the year to the end. Its not always a smooth road to get there. There's many times of discouragement
              and doubt but I've learned through my experience to personally work with each of my students and discover what they need in order to succeed and thrive.
            </p>
            <br />
            <p>
              I love life, my family, friends and all the unique experiences I've had so far. I'm married to an amazing husband who has supported me through it all and
              have the absolute cutest dog in the world. In my spare time I enjoy watching Netflix, working out, playing with my dog, being outdoors, and taking naps.
            </p>
            <br />
            <p>Teaching is my passion and I am so honored to have this unique opportunity in educating the future generation!</p>
            <br />
            <div>
              <Button
                secondary
                inverted
                onClick={handleButton}
              >
                Learn More
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Container style={contentInfoStyle}>
        <Grid container stackable centered divided>
          <Grid.Row>
            <Grid.Column width={8}>
              <h2>EXPERIENCE / SKILLS</h2>
            </Grid.Column>
            <Grid.Column width={8}>
              <List relaxed>
                <List.Item>
                  <List.Content>
                    <List.Header as='p'>Vega Elementary</List.Header>
                    <List.Description as='p'>2019 - Present</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header as='p'>Governors' Village STEM Academy</List.Header>
                    <List.Description as='p'>2018 - 2019</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header as='p'>Morehead STEM Academy</List.Header>
                    <List.Description as='p'>2014 - 2018</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
          <Divider style={dividerStyle} hidden />
          <Grid.Row>
            <Grid.Column width={8}>
              <h2>LEADERSHIP</h2>
            </Grid.Column>
            <Grid.Column width={8}>
              <List relaxed>
                <List.Item>
                  <List.Content>
                    <List.Header as='p'>Third Grade Lead Teacher</List.Header>
                    <List.Description as='p'>2018 - 2019</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header as='p'>STEM Innovator with Discovery Education</List.Header>
                    <List.Description as='p'>2018 - 2019</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header as='p'>STEM Committee Member</List.Header>
                    <List.Description as='p'>2017 - 2018</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header as='p'>School Leadership Team Co-Chair</List.Header>
                    <List.Description as='p'>2016 - 2017, 2017 - 2018</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header as='p'>Faculty Advisory Committee</List.Header>
                    <List.Description as='p'>2014 - 2015, 2015 - 2016</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
          <Divider style={dividerStyle} hidden />
          <Grid.Row>
            <Grid.Column width={8}>
              <h2>AWARDS / RECOGNITION</h2>
            </Grid.Column>
            <Grid.Column width={8}>
              <List relaxed>
                <List.Item>
                  <List.Content>
                    <List.Header as='p'>2017 UNC Charlotte Page and Ed Kizer Beginning Teacher Award</List.Header>
                    <List.Description as='p'>Nominated</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
          <Divider style={dividerStyle} hidden />
          <Grid.Row>
            <Grid.Column width={8}>
              <h2>EDUCATION</h2>
            </Grid.Column>
            <Grid.Column width={8}>
              <List relaxed>
                <List.Item>
                  <List.Content>
                    <List.Header as='p'>Dual licensure in Elementary Education and Special Education</List.Header>
                    <List.Description as='p'>University of North Carolina at Charlotte - Bachelor's Degree</List.Description>
                    <List.Description as='p'>2011 - 2014</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header as='p'>Add-On Licensure for Academically and Intellectually Gifted Education</List.Header>
                    <List.Description as='p'>University of North Carolina in Wilmington - Graduate School (Distance Education)</List.Description>
                    <List.Description as='p'>2017 - 2018</List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>

        </Grid>
      </Container>
      <Footer />
    </div>
  );
}

export default About;
