import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

import { Container, Grid, Header } from 'semantic-ui-react';
import school from '../images/school-64.png';

const BREAKPOINTS = {
  mobile: 320,
  tablet: 768,
  computer: 992,
  largeScreen: 1200,
  widescreen: 1920
}

const footerStyle = {
  padding: '40px 0px',
  backgroundColor: '#e6e6e6'
}

const footerContainerStyle = {
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center'
}

const gridStyle = {
  marginTop: '80px',
  marginBottom: '160px'
}

const headerStyle = {
  cursor: 'pointer'
}

function Footer() {
  const history = useHistory();

  const handleButton = (event) => {
    const name = event.target.id;
    if (name) {
      history.push(`/${name}`);

      ReactGA.event({
        category: 'About',
        action: 'Clicked a Navigation Button',
        label: `/${name}`
      });
    }
  }

  return (
    <div style={footerStyle}>
      <Container className="footer" style={footerContainerStyle}>
        <div>
          <img src={school} alt="School" />
        </div>
        <Grid stackable columns={4} style={window.innerWidth > BREAKPOINTS.tablet ? gridStyle : null}>
          <Grid.Column>
            <Header
              as='h3'
              id='home'
              onClick={handleButton}
              style={headerStyle}>
              Home
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header
              as='h3'
              id='experience'
              onClick={handleButton}
              style={headerStyle}>
              Experience
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header
              as='h3'
              id='about'
              onClick={handleButton}
              style={headerStyle}>
              About
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header
              as='h3'
              id='contact'
              onClick={handleButton}
              style={headerStyle}>
              Contact
            </Header>
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
}

export default Footer;
